<script lang="ts" setup>

</script>

<template>
  <v-app class="h-screen bg-gradiant">
    <v-main>
      <v-container class="px-4 " fluid>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss"></style>
